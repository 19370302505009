import { gql } from "./artifacts";

export const collectCheckoutV2Details = gql(`
  query webCheckoutV2DetailsQuery($cart: CollectCheckoutV2DetailsCartInput) {
    collectCheckoutV2Details(cart: $cart) {
      availablePaymentMethods
      fanaticsCollectSection {
        seller {
          __typename
          ...CollectSeller
        }
        shipping {
          __typename
          ...CollectCheckoutV2FanaticsShipping
        }
        lineItems {
          __typename
          ...CollectCheckoutV2LineItem
        }
        invoiceType
        totals {
          __typename
          ...CollectCheckoutV2DetailsSectionTotals
        }
        errors {
          ...CollectCheckoutV2DetailsError
        }
      }
      fanaticsAuthenticSection {
        seller {
          __typename
          ...CollectSeller
        }
        shipping {
          __typename
          ...CollectCheckoutV2FanaticsShipping
        }
        lineItems {
          __typename
          ...CollectCheckoutV2LineItem
        }
        invoiceType
        totals {
          __typename
          ...CollectCheckoutV2DetailsSectionTotals
        }
        errors {
          ...CollectCheckoutV2DetailsError
        }
      }
      burbankSportscardsSection {
        seller {
          __typename
          ...CollectSeller
        }
        shipping {
          __typename
          ...CollectCheckoutV2BurbankShipping
        }
        lineItems {
          __typename
          ...CollectCheckoutV2LineItem
        }
        invoiceType
        totals {
          __typename
          ...CollectCheckoutV2DetailsSectionTotals
        }
        errors {
          ...CollectCheckoutV2DetailsError
        }
      }
      payments {
        __typename
        ...CollectCheckoutV2Payment
      }
      pendingPayments {
        __typename
        ...CollectCheckoutV2Payment
      }
      totals {
        subTotal {
          __typename
          ...Money
        }
        pureSubTotal {
          __typename
          ...Money
        }
        shipping {
          __typename
          ...Money
        }
        tax {
          __typename
          ...Money
        }
        buyersPremium {
          __typename
          ...Money
        }
        creditCardFeeRate
        potentialCreditCardFee {
          __typename
          ...Money
        }
        total {
          __typename
          ...Money
        }
        potentialTotalWithCreditCardFee {
          __typename
          ...Money
        }
        amountPaid {
          __typename
          ...Money
        }
        amountPaidOutstanding {
          __typename
          ...Money
        }
        balanceDue {
          __typename
          ...Money
        }
        potentialBalanceDueWithCreditCardFee {
          __typename
          ...Money
        }
        accountFunds {
          __typename
          ...Money
        }
        accountFundsToUse {
          __typename
          ...Money
        }
        accountFundsRemaining {
          __typename
          ...Money
        }
        paymentMethodsFees {
          balanceDueWithFee {
            ...Money
          }
          fee {
            ...Money
          }
          feeRate
          paymentMethod
          totalWithFee {
            ...Money
          }
        }
      }
      errors {
        ...CollectCheckoutV2DetailsError
      }
    }
  }
`);
