import { IPaymentMethod } from "@fanatics-live/common-components";
import { CanMakePaymentResult } from "@stripe/stripe-js";
import { PropsWithChildren, createContext, useState } from "react";

import {
  CollectCheckoutDetails,
  CollectInvoice,
  CollectListing,
  CollectListingType,
  CollectValidPaymentMethod,
} from "@/gql";
import { ICheckoutDetailsOptions } from "@/hooks";
import { ETrackingEvents, track } from "@/utils";

interface ICartContext {
  confirmPaymentLoading: boolean;
  detailsOptions: ICheckoutDetailsOptions;
  globalErrorMessage?: string;
  paymentError?: Error;
  paymentMethod?: CollectValidPaymentMethod;
  paymentRequestMethods?: CanMakePaymentResult;
  setDetailsOptions: (options: ICheckoutDetailsOptions) => void;
  savedPaymentMethodData?: IPaymentMethod;
  setConfirmPaymentLoading: (loading: boolean) => void;
  setGlobalErrorMessage: (message?: string) => void;
  setPaymentError: (value?: Error) => void;
  setPaymentMethod: (
    paymentMethod: CollectValidPaymentMethod | undefined,
    details: CollectCheckoutDetails,
  ) => void;
  setPaymentRequestMethods: (
    paymentRequestMethods: CanMakePaymentResult,
  ) => void;
  setSavedPaymentMethodData: (paymentMethodData?: IPaymentMethod) => void;
  shippingError: boolean;
  setShippingError: (value: boolean) => void;
}

export const CartContext = createContext<ICartContext | undefined>(undefined);

export const CartProvider = ({
  invoiceId,
  listingId,
  children,
}: {
  invoiceId?: CollectInvoice["id"];
  listingId?: CollectListing["id"];
} & PropsWithChildren) => {
  const [globalErrorMessage, setGlobalErrorMessage] = useState<string>();
  const [paymentError, setPaymentError] = useState<Error>();
  const [shippingError, setShippingError] = useState(false);
  const [paymentMethod, setPaymentMethod] =
    useState<CollectValidPaymentMethod>();
  const [paymentRequestMethods, setPaymentRequestMethods] =
    useState<CanMakePaymentResult>();
  const [savedPaymentMethodData, setSavedPaymentMethodData] =
    useState<IPaymentMethod>();
  const [confirmPaymentLoading, setConfirmPaymentLoading] = useState(false);

  // These options are passed to `useQuery` when fetching the invoice or listing.
  const [detailsOptions, setDetailsOptions] = useState<ICheckoutDetailsOptions>(
    {},
  );

  // When the listing ID (Buy Now) or invoice ID has been fetched, set the
  // corresponding initial query options.
  if (invoiceId && !detailsOptions.invoiceId) {
    setDetailsOptions({ ...detailsOptions, invoiceId });
  } else if (listingId && !detailsOptions.listingId) {
    setDetailsOptions({
      ...detailsOptions,
      listingId,
      listingType: CollectListingType.FixedPrice,
    });
  }

  return (
    <CartContext.Provider
      value={{
        confirmPaymentLoading,
        detailsOptions,
        globalErrorMessage,
        setDetailsOptions,
        paymentError,
        paymentMethod,
        paymentRequestMethods,
        savedPaymentMethodData,
        setConfirmPaymentLoading,
        setGlobalErrorMessage,
        setPaymentError,
        setPaymentMethod: (paymentMethod, details) => {
          paymentError && setPaymentError(undefined);

          track(ETrackingEvents.PAYMENT_METHOD_SELECTED, {
            invoiceCategory: details.invoiceCategory,
            invoiceType: details?.type,
            listingIds: [listingId],
            paymentType: paymentMethod,
          });

          setPaymentMethod(paymentMethod);
        },
        setPaymentRequestMethods,
        setSavedPaymentMethodData,
        shippingError,
        setShippingError,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
