import { Collapsible } from "@radix-ui/react-collapsible";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { CollectCheckoutV2LineItem } from "@/gql";
import { cn } from "@/utils";

import { Button } from "../../shadcn/button";
import {
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../shadcn/collapsible";

import { LineItem } from "./LineItem";

interface ItemsSectionProps {
  lineItems: CollectCheckoutV2LineItem[];
  borderTop?: boolean;
  isPrinting?: boolean;
}

const ItemsSection: FC<ItemsSectionProps> = ({
  lineItems,
  borderTop = true,
  isPrinting,
}) => {
  const [allItemsOpen, setAllItemsOpen] = useState(false);
  const { t } = useTranslation();
  const firstItems = lineItems.slice(0, 3);
  const otherItems = lineItems.slice(3);

  return (
    <Collapsible
      open={isPrinting || allItemsOpen}
      onOpenChange={setAllItemsOpen}
    >
      <div
        className={cn(
          "px-4 py-4 md:px-6",
          borderTop && "border-t border-neutral-100",
        )}
      >
        {lineItems && (
          <>
            {firstItems.map((item, index) => {
              const isLast = index === firstItems.length - 1;
              return (
                <LineItem
                  key={item.id}
                  item={item}
                  showBorder={!isLast || allItemsOpen}
                />
              );
            })}
            {otherItems && otherItems.length > 0 && (
              <CollapsibleContent className="collapsible-styles relative">
                {otherItems.map((item, index) => {
                  const isLast = index === otherItems.length - 1;
                  return (
                    <LineItem key={item.id} item={item} showBorder={!isLast} />
                  );
                })}
              </CollapsibleContent>
            )}
          </>
        )}
      </div>
      {otherItems && otherItems.length > 0 && !isPrinting && (
        <div className="flex justify-between border-t border-neutral-100 px-4 py-4 md:px-6">
          <p className="text-body-sm">
            {t("checkout_v2.multicart.seller_section.line_items.show_items", {
              fromNumber: allItemsOpen ? lineItems.length : firstItems.length,
              toNumber: lineItems.length,
            })}
          </p>
          <CollapsibleTrigger>
            <Button
              asChild
              variant="link"
              className="h-auto p-0 text-body-sm-bold font-bold underline"
            >
              {allItemsOpen
                ? t(
                    "checkout_v2.multicart.seller_section.line_items.buttons.show_less",
                  )
                : t(
                    "checkout_v2.multicart.seller_section.line_items.buttons.view_all",
                  )}
            </Button>
          </CollapsibleTrigger>
        </div>
      )}
    </Collapsible>
  );
};

export { ItemsSection };
