export default {
  btn: {
    cancel: "Cancel",
    edit: "Edit",
    view: "View",
    apply: "Apply",
    continue: "Continue",
    error_page_button: "Pick up where you left off",
    change: "Change",
    add: "Add",
    select: "Select",
    next: "Next",
    save: "Save",
  },
  common: {
    back_to_cart: "Back To Cart",
    free: "Free",
    ok: "Ok",
    okay: "Okay",
    update_now: "Update now",
    update_later: "Update Later",
    item: "Item",
    total: "Total",
    amount: "Amount",
    note: "Note",
    description: "Description",
    unknown: "Unknown",
    header_checkout: "Checkout",
    invalid: "Invalid",
    item_one: "{{count}} item",
    item_other: "{{count}} items",
    shipment_banner: {
      save_on_shipping: {
        header: "Save on shipping & sales tax",
        title: "Want to save on shipping and sales tax?",
        text: "Next time, ship to the Vault and save {{amount}}",
      },
      shipping_duration: {
        header: "Expected Fulfillment",
        text: "Fulfillments can take up to 3-5  business days to process.  Note that fulfillments to the Vault are immediate and you save on shipping and tax.",
      },
    },
    shipping: {
      title: "Ship purchases to",
      vault: "Fanatics Collect Vault",
    },
    payment: {
      title: "Paying with",
      card_name: "{{name}} ending in ",
    },
    select_shipping_type_error: "Please select your shipping destination",
    select_shipping_method_error: "Please select your shipping method",
    select_payment_method_error: "Please select your payment method",
  },
  invoices: {
    title: "Pay now",
    sub_title: "Select the invoice you wish to pay",
    invoice_one: "Invoice",
    invoice_other: "Invoices",
    invoice_amount: "Invoice amount",
    due_on: "Due on",
    btn: {
      pay: "Pay",
      view_only: "View only",
    },
    members_invoice_page:
      "To see all of your invoices, go to <href>members/invoice page</href>.",
    no_invoices: "No invoices found.",
    underwriting_review: "Pending underwriting",
    ach_in_progress: "ACH in progress",
  },
  cart: {
    error: {
      unavailable: "Sorry, this listing or invoice was not found.",
      unexpected: "An unexpected error has occured.",
    },
    payment: {
      title: "Pay with",
      choose: "Choose a payment method",
      expired: "Expired",
      account_funds: {
        title: "Account Funds",
        balance: "Balance {{amount}}",
        applied: "Applied {{amount}}",
      },
      wire: {
        title: "Wire Transfer",
        subtitle: "Please click Confirm and Pay",
        complete: "Please complete your wire transfer payment",
        description:
          "Once we have received your wire transfer, the transaction will be complete and the invoice will be closed.",
        pre_invoice_description:
          "Instructions will be shown on the final confirmation page after confirming your purchase. Once we have received your wire transfer, the transaction will be complete and the invoice will be closed.",
        content:
          "<strong>Route to:</strong>\n\n" +
          "Account: <strong>009660122426</strong>\n" +
          "Wire Routing: <strong>053100300</strong>\n" +
          "Swift Code: <strong>FCBTUS33</strong>\n\n" +
          "PWCC Marketplace LLC Operations\n" +
          "7560 SW Durham Rd\n" +
          "Tigard, OR 97224\n" +
          "First Citizens Bank\n\n" +
          "On memo line include: <strong>{{invoiceId}}</strong>",
      },
      ach: {
        title: "Payment Status",
        description:
          "Once we have received your payment, the transaction will be complete and the invoice will be closed.",
      },
      add_payment_method: {
        add_new_card: "+ Add a new Credit Card",
        add_new_banking_account: "+ Add a new Banking Account",
        subtitle: "Your payment is secure.",
      },
      mobile: {
        btn: "Next",
        payment_method: {
          add_new_card: "Add credit card",
          add_new_us_bank_account: "Add bank account",
        },
      },
      desktop: {
        btn: "Done",
        payment_method: {
          add_new_card: "+ Add a new credit card",
          add_new_us_bank_account: "+ Add a new US bank account",
        },
      },
    },
    shipping: {
      title: "Ship to",
      selected: "Selected",
      vault: {
        title: "My Vault",
        free: "Free",
        id: "ID: {{number}}",
        secure: "Secure & Fully Insured",
        tax: "0% State Tax",
      },
      primary: {
        cost: "${{cost}}",
        from_cost: "From ${{cost}}",
        tax: "+ Tax",
        time: "Estimated 5-7 Day Shipping Time",
      },
      alternate: {
        title: "Alternate Address",
        form_title: "Use alternate address",
        invalid_message:
          "Invalid shipping address. Please correct any errors and try again.",
        name: "Name",
        address_1: "Address",
        address_2: "Apt. or Suite # (Optional)",
        city: "City",
        state: "State",
        province: "Province/Region",
        zip: "Zip/Postal Code",
        country: "Country",
        phone: "Phone",
      },
      default: {
        invalid_message:
          "Invalid shipping address. Please correct your address or use an alternate address.",
      },
      banner: {
        title: "Sending multiple items to the same shipping address?",
        message:
          "The most cost effective way to achieve this is to ship to the Vault, and then retrieve all items in one go.",
      },
      burbank_banner: {
        title: "Ship to Vault Unavailable",
        message:
          "This item is sold by Burbank Sportscards, so shipping it directly to our Vault is not an option. Please select a personal shipping address.",
      },
    },
    invoice: {
      title: "Invoice #{{id}}",
      items_one: "{{count}} item",
      items_other: "{{count}} items",
      items_zero: "Items",
      weekly_items: "Auction\n Items: {{count}}",
      premier_items: "Premier\n Items: {{count}}",
      fixed_items: "Buy Now\n Items: {{count}}",
      vault_items: "Vault\n Items: {{count}}",
      capital_items: "Capital",
      special_items: "Special",
      subtotal: "Subtotal ({{count}} items):",
      trade_show: "Only shipping charges are applied for trade show pickup.",
    },
    items: {
      title: "Items ({{count}})",
      title_zero: "Items",
    },
    confirm: {
      title: "Order Total",
      subtotal: "Subtotal",
      buyers_premium: "Buyer's Premium (20%)",
      tax: "Tax",
      invoice_total: "Invoice total",
      account_funds: "Account Funds",
      balance_remaining: "Total Due Today:",
      amount_paid: "Paid:",
      btn: "Confirm & Pay",
      terms:
        "By clicking Confirm & Pay, you agree to the <href1>Privacy Policy</href1>, <href2>Terms of Use</href2>, <href3>Cookie Policy</href3>, and <href4>Return Policy</href4>.",
    },
  },
  confirmation_page: {
    title: {
      paid: "Payment Confirmed",
      pending: "Payment Pending",
      unpaid: "Payment Not Confirmed",
    },
    shipping: {
      title: "Shipping Status",
      message: {
        vault:
          "Your items have been securely transferred to your vault for free.",
        marketplace:
          "We are now preparing your item(s) for shipping. Please keep an eye out for an email with tracking information.",
        pending: "Once payment is received assets will be transferred",
      },
    },
    customer: {
      title: "Customer Information",
      email: "Email",
      shipping_address: "Shipping Address",
      vault_address: "Vault Address",
      payment_method: "Payment Method",
    },
    button: {
      back: "Back",
      download: "Download Invoice",
      file_name: "invoice-{{id}}.pdf",
    },
  },
  order_details: {
    back_to_invoices: "Back to invoices",
    title: "Thank you",
    sub_title: "Order details",
    download_all: "Download full version",
    download_summary: "Download summary",
    order_details_pdf: "Order Details #{{invoiceId}}.pdf",
    order_summary_pdf: "Order Summary #{{invoiceId}}.pdf",
    paying_with: {
      payment_with_pending: "{{paymentType}} - Payment Pending",
      payment_accepted: "Your payment has been accepted.",
      payment_pending_one:
        "Your payment is pending, and will be marked complete once the {{paymentMethods}} is received and logged by staff.",
      payment_pending_other:
        "Your payment is pending, and will be marked complete once the {{paymentMethods}} are received and logged by staff.",
      payment_partial_confirmed_one:
        "Your partial payment via {{paymentMethods}} is confirmed",
      payment_partial_confirmed_other:
        "Your partial payment via {{paymentMethods}} are confirmed",
      payment_partial_remaining_one:
        "and your remaining {{paymentMethods}} portion is pending. This invoice will be marked complete once the {{paymentMethods}} is received and logged by staff.",
      payment_partial_remaining_other:
        "and your remaining {{paymentMethods}} portions are pending. This invoice will be marked complete once the {{paymentMethods}} are received and logged by staff.",
      payment_ids_one: "Your Payment ID is <strong>{{paymentIds}}</strong>.",
      payment_ids_other:
        "Your Payment IDs are <strong>{{paymentIds}}</strong>.",
    },
    account_funds: {
      title: "Account Funds",
      content: "on-account funds",
    },
    credit_card: {
      title: "Credit Card",
      content: "credit card",
    },
    ach: {
      title: "ACH",
      content: "ACH",
    },
    wire: {
      title: "Wire",
      content: "Wire",
    },
    check: {
      title: "Check",
      content: "check",
    },
    crypto: {
      title: "Crypto",
      content: "crypto",
      info: "Please contact one of our specialists at <href>cs@fanaticscollect.com</href> to finalize the payment.",
    },
    apple_pay: {
      title: "Apple Pay",
      content: "apple pay",
    },
    google_pay: {
      title: "Google Pay",
      content: "google pay",
    },
    other: {
      title: "Other",
      content: "other",
    },
    purchase_overview: "Purchase overview",
    payment_id_one: "Payment ID: <strong>{{paymentId}}</strong>",
    payment_id_other: "Payment IDs: <strong>{{paymentId}}</strong>",
    invoice_id: "Invoice ID: <strong>{{invoiceId}}</strong>",
    invoice_sent: "Invoice sent: {{date}}",
    paid_status: "Paid status: {{status}}",
    paid_on: "Paid on: {{date}}",
    footer_title: "Thank you for your payment.\n-Fanatics Collect Team",
    footer_sub_title: "Need Help? Email us at cs@fanaticscollect.com",
    quantity: "Quantity",
    amount: "Amount",
    general_details: "General details",
    your_info: "Your information",
    item_description: "Item Description",
    description_for_invoice: "Description for invoice #{{invoiceId}}",
    weekly_items: "Auction Items",
    premier_items: "Premier Items",
    fixed_items: "Buy Now Items",
    vault_items: "Vault Items",
    capital_items: "Capital Items",
    special_items: "Special Items",
    by_confirming_paying:
      "By confirming & paying, you agree to be bound by the Fanatics Collect <href1> terms & conditions </href1>. For more information, see our <href2>cancellation & return policy</href2>.",
  },
  order_summary: {
    title: "Order Summary",
    view: "View Details",
    invoice: "Invoice",
    invoice_amount: "Invoice amount",
    shipping: "Shipping",
    handling: "Handling",
    tax: "Sales Tax",
    total_due: "Total due:",
    account_funds: "Account Funds",
    credit_card: "Credit Card",
    credit_card_fee: "Credit Card Fee (2.9%)",
    credit_card_fee_label: "{{percentage}}% fee",
    credit_card_fee_percentage: "2.9% fee",
    ach: "ACH",
    wire: "Wire",
    check: "Check",
    crypto: "Crypto",
    apple_pay: "Apple Pay",
    google_pay: "Google Pay",
    other: "Other",
    payment_with_pending: "{{paymentType}} (Pending)",
    balance_remaining: "Balance remaining:",
    invoice_summary: "Invoice summary",
    resale_permit: "Resale permit on file",
    free_to_vault: "(<strong>$0</strong> if shipped to your Vault)",
    free_you_are_exempt: "(<strong>$0</strong> Sales tax — You are exempt)",
    no_listed_items: "There are no listed items.",
  },
  alert: {
    title: {
      invalid_address: "Your address is invalid",
      unsuccessful_payment: "Unsuccessful payment",
      unknown: "Unknown error",
    },
    content: {
      update_address_info: "Please update your address information",
      review_provided_info:
        "Please review your provided information and try again",
      review_stripe_info:
        "Please make sure your {{paymentType}} information is correct and try again",
      customer_support:
        "Something went wrong. Please send an email to <href>cs@fanaticscollect.com</href>",
    },
  },
  errors: {
    page_not_found: "The page you’re looking for couldn’t be found.",
    frozen_user:
      "The account is currently under review. Please contact customer service at cs@fanaticscollect.com",
  },
  validation_errors: {
    state_required: "State is required",
    region_required: "Province/Region is required (use country if none)",
    country_required: "Country is required",
    street_required: "Street is required",
    city_required: "City is required",
    name_required: "Name is required",
    phone_required: "Phone number is required",
    phone_invalid: "Phone number is invalid",
    zip_required: "Zip is required",
    zip_invalid: "Zip code is invalid",
    email_required: "Email is required",
    email_invalid: "Email is invalid",
    terms_required: "Please accept the required terms & conditions",
    account_funds: {
      empty: "Please enter amount",
      lowers_outstanding:
        "Please pay the full invoice amount, or leave at least {{amount}} to be paid with another method",
      exceeds_available: "Exceeds available funds",
      exceeds_invoice_amount: "Exceeds invoice amount",
    },
    complete_form: "Please complete the form below",
  },
  info_message: {
    unpaid_invoice:
      "To complete this request, please pay for these outstanding vault item fees",
    unpaid_invoices:
      "To complete this request, please pay the following unpaid invoices",
  },
  danger_message: {
    force_payment:
      "According to our records you have invoice(s) past due. These need to be paid in order to regain full account access. If you need to chat with a representative, please use the chat feature, or email us with questions at cs@fanaticscollect.com",
  },
  checkout_v2: {
    multicart: {
      seller_section: {
        sold_by: {
          fanatics_collect:
            "<strong>{{count}} items</strong> fulfilled by Fanatics Collect",
          burbank:
            "<strong>{{count}} items</strong> sold by Burbank Sports Cards",
          fanatics_authentic:
            "<strong>{{count}} items</strong> sold by Fanatics Authentic",
        },
        free_shipping_banner: {
          title: "You’re ${{amount}} away from free shipping with {{seller}}",
          button: "visit store",
        },
        listing_text: {
          buy_now: "Buy Now Marketplace",
          weekly: "Weekly Auction",
          premier: "Premier Auction",
          bo: "Back Office",
        },
        buttons: {
          edit_address: "Edit address",
        },
        collect_pickup: {
          title: "Pick up from Collect Vault",
          description: "Fulfillment Fees Waived, 0% State Tax",
        },
        vault_address: {
          title: "Collect Vault",
          free_badge: "Free",
          description: "Secure, 0% State Tax",
        },
        line_items: {
          buttons: {
            view_all: "View all",
            show_less: "Show less",
          },
          show_items: "Showing {{fromNumber}} of {{toNumber}}",
        },
        shipping: {
          ship_to: "Ship To",
          from: "From",
          buttons: {
            change: "Change",
            cancel: "Cancel",
            add_shipping_address: "Add Shipping Address",
          },
          address_error_message:
            "Invalid shipping address. Please correct your address or use an alternate address.",
        },
      },
      confirm: {
        title: "Order summary",
        item: "item",
        items: "items",
        subtotal: "Subtotal",
        total: "Total",
        shipping: "Shipping",
        buyers_premium: "Buyer's Premium (20%)",
        taxes: "Taxes",
        tax: "Tax",
        invoice_total: "Total",
        account_funds: "Account Funds",
        balance_remaining: "Total Due Today:",
        amount_paid: "Paid:",
        btn: "Confirm & Pay",
        terms:
          "By clicking Confirm & Pay, you agree to the <href1>Privacy Policy</href1>, <href2>Terms of Use</href2>, <href3>Cookie Policy</href3>, and <href4>Return Policy</href4>.",
      },
      banner: {
        title: "Items no longer available",
        description:
          "Unfortunately, the following items are no longer available and have been removed from checkout. We apologise for any inconvenience, but recommend that you check our marketplace for similar items.",
      },
      order_summary: {
        credit_card_fee: "Credit Card Fee",
      },
      payment_summary: {
        title: "Payment Summary",
      },
      payment_section: {
        default: "Default",
        credit_card_fee_percentage: "+2.9%",
        buttons: {
          add_credit_card: "Add Credit Card",
          add_bank_account: "Add US Bank Account",
        },
        account_funds: {
          title: "Account Funds",
          available: "Available balance: {{amount}}",
          to_be_used: "Amount to be used: {{amount}}",
          and: "and",
        },
      },
      unavailable_dialog: {
        title: "Items no longer available",
        description:
          "Unfortunately, the following items are no longer available and have been removed from checkout. We apologize for any inconvenience, but recommend that you check our marketplace for similar items.",
        description_2:
          "<strong>You have not been charged,</strong> please review and try checking out again.",
        btn: "Back to checkout",
      },
    },
    details: {
      banner: {
        title: {
          complete: "Order complete",
          pending: "Payment pending",
          failed: "Payment failed",
        },
        subtitle:
          "<strong>Order #</strong> {{invoiceId}} <strong>Placed on</strong> {{date}}",
      },
      payment: {
        title: "Payment",
        badge: {
          pending: "Awaiting Payment",
          confirmed: "Payment Confirmed",
          failed: "Payment Failed",
        },
        funds: {
          title: "Account Funds",
        },
        credit_card: {
          title: "Credit Card",
        },
        ach: {
          title: "ACH",
        },
        wire: {
          title: "Wire Transfer",
          content: {
            account: "Account #",
            wire_routing: "Routing #",
            swift_code: "Swift Code",
            recipient_name: "Recipient Name",
            bank_name: "Bank Name",
            address: "Recipient Address",
            bank_address: "Bank Address",
            ach_routing: "ACH Routing Number",
            reference: "Reference/Memo",
          },
        },
        apple_pay: {
          title: "Apple Pay",
        },
        google_pay: {
          title: "Google Pay",
        },
        other: {
          title: "Other",
        },
        crypto: {
          title: "Crypto",
        },
        check: {
          title: "Check",
        },
        pending_payment_message: {
          wire: "Once we have received your wire transfer, the transaction will be completed and the order will be shipped.",
          ach: "Once we have received your payment, the transaction will be completed and the order will be shipped.",
        },
      },
      shipping: {
        pending_shipping_message:
          "Once payment has been received items will be shipped.",
      },
      summary: {
        order: {
          total_due: "Total Due",
        },
        payment: {
          title: "Payment Summary",
          paid: "Paid",
          remaining: "Remaining",
          awaiting_payment: "Awaiting payment...",
        },
      },
    },
  },
  back_office: {
    order_items: {
      table: {
        item: "Item",
        quantity: "Quantity",
        price: "Price",
        total: "Total",
      },
    },
  },
  breadcrumb: {
    home: "Home",
    orders: "orders",
    order: "Order {{invoiceId}}",
  },
};
