import { booleanParser, numberParser } from "./split";

export enum FeatureFlags {
  WEB_ENABLE_PLAID_PA_APPROVAL = "Web_Enable_Plaid_PA_Approval",
  DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE = "Datadog_Browser_Logs_Session_Sample_Rate",
  WEB_ENABLE_FETCH_PUBLISHABLE_KEY = "Web_Enable_Fetch_Publishable_key",
}

export const TreatmentParsersMap = {
  [FeatureFlags.WEB_ENABLE_PLAID_PA_APPROVAL]: booleanParser,
  [FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE]: (
    treatmentWithConfig: SplitIO.TreatmentWithConfig,
  ) => numberParser(treatmentWithConfig, 50),
  [FeatureFlags.WEB_ENABLE_FETCH_PUBLISHABLE_KEY]: booleanParser,
} as const satisfies Record<FeatureFlags, unknown>;

export type FeatureValue<TFeatureName extends FeatureFlags> = ReturnType<
  (typeof TreatmentParsersMap)[TFeatureName]
>;
