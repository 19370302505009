import { Flex, Stack, Text, Link, Button } from "@chakra-ui/react";
import {
  EButtonVariant,
  EColor,
  ETextVariant,
} from "@fanatics-live/common-components";
import { Trans, useTranslation } from "react-i18next";

import { ErrorMessage, AppleIcon, GoogleIcon } from "@/components";
import { COOKIE_URL, PRIVACY_URL, RETURNS_URL, TERMS_URL } from "@/env";
import { CollectValidPaymentMethod } from "@/gql";
import {
  useCartContext,
  useCheckoutDetails,
  useConfirmPayment,
  useCreateStripePaymentRequest,
} from "@/hooks";

import { hasShipping } from "../ShippingSection/utils";

export const ConfirmPaymentButton = () => {
  const { t } = useTranslation();
  const { paymentError, paymentMethod, detailsOptions, setShippingError } =
    useCartContext();
  const { confirmPayment, loading } = useConfirmPayment();
  const paymentRequest = useCreateStripePaymentRequest();
  const { details, loading: detailsLoading } =
    useCheckoutDetails(detailsOptions);

  const isCheckoutDetailsError = details?.errors && details?.errors.length > 0;

  const isApplePay = paymentMethod === CollectValidPaymentMethod.ApplePay;
  const isGooglePay = paymentMethod === CollectValidPaymentMethod.GooglePay;

  const checkIsShippingMissed = () => {
    const isShippingRequired = hasShipping(details);

    if (!isShippingRequired) {
      return false;
    }

    const isBurbank = !!details?.availableShippingMethods?.length;
    const chosenShippingMethod = detailsOptions.shippingMethodId;
    const chosenShippingType = detailsOptions.shippingType;

    if (isBurbank) {
      return !chosenShippingMethod || !chosenShippingType;
    }

    return !chosenShippingType;
  };

  const handleConfirmPayment = () => {
    const isShippingMissed = checkIsShippingMissed();

    if (isShippingMissed) {
      setShippingError(true);
      return;
    } else {
      setShippingError(false);
    }

    if (isApplePay || isGooglePay) {
      // Open the Stripe dialog, which upon confirmation will trigger the
      // 'paymentmethod' event handler attached in
      // `useConfirmStripePaymentRequest` to complete the payment.
      paymentRequest?.show();
    }

    confirmPayment(paymentRequest);
  };

  return (
    <Flex
      justify="center"
      pt={{ base: "18px", md: "14px" }}
      pb={{ base: "20px", md: 0 }}
      borderBottomWidth={{ base: "1px", md: 0 }}
      borderColor={EColor.Neutral15}
    >
      <Stack w="100%" direction={{ base: "column", md: "row" }}>
        <Flex flex={1} flexDirection="column" px={{ base: "4px", md: 0 }}>
          <Flex flexDir="column" mb={0} pt={{ base: "8px", md: 0 }}>
            <Button
              isDisabled={
                !!paymentError ||
                loading ||
                !paymentMethod ||
                !!isCheckoutDetailsError ||
                detailsLoading
              }
              isLoading={loading}
              variant={EButtonVariant.BRAND_PRIMARY}
              onClick={handleConfirmPayment}
            >
              {isApplePay ? (
                <>
                  Pay with{" "}
                  <AppleIcon position="relative" bottom="2px" ml="4px" /> Pay
                </>
              ) : isGooglePay ? (
                <>
                  Pay with <GoogleIcon ml="4px" /> Pay
                </>
              ) : (
                t("cart.confirm.btn")
              )}
            </Button>
            {!!paymentError && <ErrorMessage message={paymentError.message} />}
          </Flex>

          <Text
            my={{ base: "16px", md: "16px" }}
            variant={ETextVariant.Paragraph5}
            fontSize="10px"
            color={EColor.BrandWarning}
            mb={{ base: 0, md: "16px" }}
          >
            <Trans
              i18nKey="cart.confirm.terms"
              components={{
                href1: (
                  <Link
                    color={EColor.BrandDark}
                    fontWeight="500"
                    href={PRIVACY_URL}
                    textDecoration="underline"
                    isExternal
                    data-testid="privacy-link"
                  />
                ),

                href2: (
                  <Link
                    color={EColor.BrandDark}
                    fontWeight="500"
                    href={TERMS_URL}
                    textDecoration="underline"
                    isExternal
                    data-testid="terms-link"
                  />
                ),

                href3: (
                  <Link
                    color={EColor.BrandDark}
                    fontWeight="500"
                    href={COOKIE_URL}
                    textDecoration="underline"
                    isExternal
                    data-testid="cookie-link"
                  />
                ),

                href4: (
                  <Link
                    color={EColor.BrandDark}
                    fontWeight="500"
                    href={RETURNS_URL}
                    textDecoration="underline"
                    isExternal
                    data-testid="return-policy-link"
                  />
                ),
              }}
            />
          </Text>
        </Flex>
      </Stack>
    </Flex>
  );
};
