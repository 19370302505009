import { FC } from "react";

import { OctagonAlert } from "@/components/icons";

type ErrorNotificationProps = {
  errorText: string;
  className?: string;
};

const ErrorNotification: FC<ErrorNotificationProps> = ({
  className,
  errorText,
}) => {
  return (
    <div className={className}>
      <div className="flex items-center gap-2 rounded-lg bg-red-100 p-3 text-body-sm-bold text-neutral-950">
        <OctagonAlert className="h-4 w-4" />
        <p>{errorText}</p>
      </div>
    </div>
  );
};

export { ErrorNotification };
