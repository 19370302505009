import { FC } from "react";

import {
  CollectAddress,
  CollectBoShippingAddressInput,
  CollectCheckoutV2Address,
  CollectCheckoutV2AddressInput,
} from "@/gql";
import { joinOptionalStrings } from "@/utils";

interface AddressTextProps {
  address:
    | CollectAddress
    | CollectCheckoutV2Address
    | CollectCheckoutV2AddressInput
    | CollectBoShippingAddressInput;
  userCountryName: string;
}

const AddressText: FC<AddressTextProps> = ({ address, userCountryName }) => {
  const { city, state } = address;

  const addressLine2 =
    "street1" in address
      ? address.street2
      : "addressLine2" in address
        ? address.addressLine2
        : "line2" in address
          ? address.line2
          : null;

  const addressLine1 =
    "street1" in address
      ? address.street1
      : "addressLine1" in address
        ? address.addressLine1
        : "line1" in address
          ? address.line1
          : "";

  const zipCode =
    "postalCode" in address
      ? address.postalCode
      : "zip" in address
        ? address.zip
        : "";

  const globalAddress = joinOptionalStrings(
    ", ",
    city ?? "",
    joinOptionalStrings(" ", state ?? "", zipCode ?? ""),
  );

  return (
    <div className="flex flex-col text-body-sm text-neutral-800">
      {addressLine1 ? <p>{addressLine1}</p> : null}
      {addressLine2 ? <p>{addressLine2}</p> : null}
      {globalAddress ? <p>{globalAddress}</p> : null}
      {userCountryName ? <p>{userCountryName}</p> : null}
    </div>
  );
};

export { AddressText };
