import { cva, type VariantProps } from "class-variance-authority";
import { Check, Clock3 } from "lucide-react";

import { OctagonAlert } from "@/components/icons";
import { CheckIcon } from "@/components/icons/Check";
import { cn } from "@/utils";

const statusBannerVariants = cva(
  "w-full  py-16 px-8 flex flex-col bg-red-200 items-center justify-center text-headline-4xl gap-4",
  {
    variants: {
      variant: {
        pending: "bg-orange-100 text-orange-700 stroke-orange-700 fill-none ",
        placed:
          "bg-order-placed bg-cover bg-no-repeat text-neutral-950 stroke-neutral-950 fill-none",
        error: "bg-red-100 text-red-700 stroke-red-700 fill-none",
        complete: "text-green-700 bg-green-100 fill-none ",
      },
    },
  },
);

export type OrderStatusVariant = NonNullable<
  VariantProps<typeof statusBannerVariants>["variant"]
>;

const statusIcons: Record<OrderStatusVariant, JSX.Element | undefined> = {
  pending: (
    <Clock3 className="h-12 w-12 fill-inherit stroke-inherit text-inherit" />
  ),
  placed: (
    <Check className="h-12 w-12 fill-inherit stroke-inherit text-inherit" />
  ),
  error: <OctagonAlert className="h-11 w-11" />,
  complete: <CheckIcon />,
};

export type StatusBannerProps = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  variant: OrderStatusVariant;
  className?: string;
};

const StatusBanner = ({
  className,
  variant,
  title,
  subtitle,
  ...props
}: StatusBannerProps) => {
  return (
    <div
      className={cn(statusBannerVariants({ variant, className }))}
      {...props}
    >
      <div
        className={cn("flex items-center justify-center gap-2", {
          "flex-col": variant === "placed",
        })}
      >
        {variant && statusIcons[variant]}
        <h4>{title}</h4>
      </div>
      <div className="text-body-sm text-inherit">{subtitle}</div>
    </div>
  );
};

StatusBanner.displayName = "StatusBanner";

export { StatusBanner, statusBannerVariants };
