import {
  CardDiscoverIcon,
  CreditCardIcon,
  ECardBrand,
} from "@fanatics-live/common-components";
import { FC } from "react";

import { CardAmex, CardMastercard, CardVisa } from "../icons";

type CardIconProps = {
  brand: ECardBrand;
};

const CardIcon: FC<CardIconProps> = ({ brand }) => {
  const sizes = { width: "36px", height: "24px" };

  const cardIconMap = {
    [ECardBrand.VISA]: <CardVisa {...sizes} />,
    [ECardBrand.MASTERCARD]: <CardMastercard {...sizes} />,
    [ECardBrand.AMEX]: <CardAmex {...sizes} />,
    [ECardBrand.DISCOVER]: <CardDiscoverIcon {...sizes} />,
    [ECardBrand.UNKNOWN]: <CreditCardIcon {...sizes} />,
  };
  return cardIconMap[brand];
};

export { CardIcon };
