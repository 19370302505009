import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "@/components/checkoutV2/common";
import { EBadgeType } from "@/types/badgeTypes";

interface VaultAddressProps {
  showShipTo?: boolean;
}

const VaultAddress: FC<VaultAddressProps> = ({ showShipTo = false }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex items-center gap-1">
        {showShipTo && (
          <p className="text-body-md-bold">
            {t("checkout_v2.multicart.seller_section.shipping.ship_to")}
          </p>
        )}
        <p className="text-body-md-bold">
          {t("checkout_v2.multicart.seller_section.vault_address.title")}
        </p>
        <Badge type={EBadgeType.Success}>
          {t("checkout_v2.multicart.seller_section.vault_address.free_badge")}
        </Badge>
      </div>
      <p className="text-body-sm text-neutral-800">
        {t("checkout_v2.multicart.seller_section.vault_address.description")}
      </p>
    </div>
  );
};

export { VaultAddress };
