import { Text } from "@chakra-ui/react";
import { ETextVariant } from "@fanatics-live/common-components";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { ApplePayIcon, GooglePayIcon, WireTransferIcon } from "@/components";
import { CollectValidPaymentMethod } from "@/gql";
import { hasCreditCardFee } from "@/utils/payment";

import { CreditCardFeeBadge } from "./CreditCardFeeBadge";

type TAdditionalPaymentMethod =
  | CollectValidPaymentMethod.ApplePay
  | CollectValidPaymentMethod.GooglePay
  | CollectValidPaymentMethod.Wire;

export const isAdditionalPaymentMethod = (
  paymentMethod: CollectValidPaymentMethod,
): paymentMethod is TAdditionalPaymentMethod =>
  [
    CollectValidPaymentMethod.ApplePay,
    CollectValidPaymentMethod.GooglePay,
    CollectValidPaymentMethod.Wire,
  ].includes(paymentMethod);

const getMethodConfig = (
  paymentMethod: TAdditionalPaymentMethod,
  t: TFunction,
  creditCardFeePercentage: number | undefined,
) => {
  const sizes = { width: "36px", height: "24px" };
  const hasCCFee = hasCreditCardFee(paymentMethod);

  switch (paymentMethod) {
    case CollectValidPaymentMethod.ApplePay:
      return {
        icon: <ApplePayIcon {...sizes} />,
        text: t("order_details.apple_pay.title"),
        badge: hasCCFee && creditCardFeePercentage && (
          <CreditCardFeeBadge
            creditCardFeePercentage={creditCardFeePercentage}
          />
        ),
      };
    case CollectValidPaymentMethod.GooglePay:
      return {
        icon: <GooglePayIcon {...sizes} />,
        text: t("order_details.google_pay.title"),
        badge: hasCCFee && creditCardFeePercentage && (
          <CreditCardFeeBadge
            creditCardFeePercentage={creditCardFeePercentage}
          />
        ),
      };
    case CollectValidPaymentMethod.Wire:
      return {
        icon: <WireTransferIcon {...sizes} />,
        text: t("cart.payment.wire.title"),
        badge: hasCCFee && creditCardFeePercentage && (
          <CreditCardFeeBadge
            creditCardFeePercentage={creditCardFeePercentage}
          />
        ),
      };
  }
};

export const AdditionalPaymentMethod = ({
  paymentMethod,
  creditCardFeePercentage,
}: {
  paymentMethod: TAdditionalPaymentMethod;
  creditCardFeePercentage: number | undefined;
}) => {
  const { t } = useTranslation();
  const config = getMethodConfig(paymentMethod, t, creditCardFeePercentage);

  return (
    <>
      {config?.icon}

      <Text variant={ETextVariant.BaseBold}>{config?.text}</Text>

      {config?.badge}
    </>
  );
};
