import { FC } from "react";

import { cn } from "@/utils";

type SkeletonProps = {
  width?: string;
  height?: string;
  widthFull?: boolean;
  heightFull?: boolean;
  roundedFull?: boolean;
  opacity?: string;
  rounded?: string;
  className?: string;
};

export const Skeleton: FC<SkeletonProps> = ({
  width = "w-24",
  height = "h-4",
  widthFull = false,
  heightFull = false,
  roundedFull = false,
  opacity = "",
  rounded = "",
  className = "",
}) => {
  return (
    <div
      className={cn(
        `shrink-0 animate-pulse bg-neutral-100`,
        widthFull ? "w-full" : width,
        heightFull ? "h-full" : height,
        rounded ? rounded : roundedFull ? "rounded-full" : "rounded-md",
        opacity && opacity,
        className,
      )}
    ></div>
  );
};
