import { useTranslation } from "react-i18next";

import { cn, formatMonetaryValue } from "@/utils";

import { Skeleton } from "./Skeleton";

export enum EConfirmSectionRecordType {
  Default = "default",
  Total = "total",
  TotalDue = "total-due",
}

type Props = {
  title: string | JSX.Element;
  value?: number;
  type?: EConfirmSectionRecordType;
  replaceZeroValue?: boolean;
  isLoading?: boolean;
  valueColor?: string;
  className?: string;
};

export const SummaryRecord = ({
  title,
  value,
  replaceZeroValue,
  isLoading,
  valueColor,
  className,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        "flex w-full justify-between font-ABCDiatype text-body-md leading-6 text-neutral-950",
        className,
      )}
    >
      {isLoading && <Skeleton className="h-6 w-full" />}
      {!isLoading && (
        <>
          <div>{title}</div>
          <div className="flex flex-col items-end">
            <p className={cn(`text-[${valueColor}]` && valueColor)}>
              {value !== undefined ? (
                value === 0 && replaceZeroValue ? (
                  t("common.free")
                ) : (
                  formatMonetaryValue(value)
                )
              ) : (
                <span className="text-red-700">{t("common.invalid")}</span>
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
};
