import { IPaymentMethod } from "@fanatics-live/common-components";
import { CanMakePaymentResult } from "@stripe/stripe-js";
import { create } from "zustand";

import { getSectionField } from "@/components/checkoutV2/multicart/SellersInfo/utils";
import {
  CollectCheckoutV2Details,
  CollectCheckoutV2DetailsBurbankSportscardsShippingInput,
  CollectCheckoutV2DetailsFanaticsAuthenticShippingInput,
  CollectCheckoutV2DetailsFanaticsCollectShippingInput,
  CollectCheckoutV2PaymentMethod,
  CollectCheckoutV2LineItem,
  CollectCheckoutV2Seller,
  Maybe,
  CollectCheckoutV2AddressInput,
} from "@/gql";
import { AlternateAddress, ShippingCost } from "@/hooks/useCheckoutV2Details";
import { ETrackingEvents, track } from "@/utils";

type MulticartStore = {
  cartId?: string;
  setCartId: (cartId?: string) => void;

  confirmPaymentLoading: boolean;
  setConfirmPaymentLoading: (loading: boolean) => void;

  globalErrorMessage?: string;
  setGlobalErrorMessage: (message?: string) => void;

  paymentError?: Error;
  setPaymentError: (value?: Error) => void;

  paymentMethod?: CollectCheckoutV2PaymentMethod;
  setPaymentMethod: (
    paymentMethod: CollectCheckoutV2PaymentMethod | undefined,
    details: CollectCheckoutV2Details,
  ) => void;

  burbankSportscardsSection?: {
    shipping: CollectCheckoutV2DetailsBurbankSportscardsShippingInput;
  } & AlternateAddress &
    ShippingCost;
  fanaticsAuthenticSection?: {
    shipping: CollectCheckoutV2DetailsFanaticsAuthenticShippingInput;
  } & AlternateAddress &
    ShippingCost;
  fanaticsCollectSection?: {
    shipping: CollectCheckoutV2DetailsFanaticsCollectShippingInput;
  } & AlternateAddress &
    ShippingCost;

  getShipping: (
    seller: CollectCheckoutV2Seller,
  ) =>
    | CollectCheckoutV2DetailsBurbankSportscardsShippingInput
    | CollectCheckoutV2DetailsFanaticsAuthenticShippingInput
    | CollectCheckoutV2DetailsFanaticsCollectShippingInput
    | undefined;

  getAlternateAddress: (
    seller: CollectCheckoutV2Seller,
  ) => CollectCheckoutV2AddressInput | undefined;

  setSectionAlternateAddress: (
    address: CollectCheckoutV2AddressInput,
    seller: CollectCheckoutV2Seller,
  ) => void;

  updateSectionShipping: (
    shipping: Partial<
      | CollectCheckoutV2DetailsBurbankSportscardsShippingInput
      | CollectCheckoutV2DetailsFanaticsAuthenticShippingInput
      | CollectCheckoutV2DetailsFanaticsCollectShippingInput
    > & { methodId?: string },
    seller: CollectCheckoutV2Seller,
  ) => void;

  setSectionDefaultAddressCost: (
    cost: number,
    seller: CollectCheckoutV2Seller,
  ) => void;
  setSectionAlternateAddressCost: (
    cost: number,
    seller: CollectCheckoutV2Seller,
  ) => void;

  getSectionDefaultAddressCost: (
    seller: CollectCheckoutV2Seller,
  ) => number | undefined;
  getSectionAlternateAddressCost: (
    seller: CollectCheckoutV2Seller,
  ) => number | undefined;

  paymentRequestMethods?: CanMakePaymentResult;
  setPaymentRequestMethods: (
    paymentRequestMethods: CanMakePaymentResult,
  ) => void;

  savedPaymentMethodData?: IPaymentMethod;
  setSavedPaymentMethodData: (paymentMethodData?: IPaymentMethod) => void;

  availableItems?: Maybe<CollectCheckoutV2LineItem>[];
  setAvailableItems: (items?: Maybe<CollectCheckoutV2LineItem>[]) => void;

  unavailableItems?: Maybe<CollectCheckoutV2LineItem>[];
  setUnavailableItems: (items?: Maybe<CollectCheckoutV2LineItem>[]) => void;

  unavailableItemsError?: boolean;
  setUnavailableItemsError: (value?: boolean) => void;

  checkoutDetails?: CollectCheckoutV2Details;
  setCheckoutDetails: (details?: CollectCheckoutV2Details) => void;

  shippingError?: boolean;
  setShippingError: (value?: boolean) => void;
};

export const useMulticartStore = create<MulticartStore>((set, get) => ({
  cartId: undefined,
  setCartId: (cartId) => {
    set(() => ({ cartId }));
  },
  confirmPaymentLoading: false,
  setConfirmPaymentLoading: (value) => {
    set(() => ({ confirmPaymentLoading: value }));
  },
  globalErrorMessage: undefined,
  setGlobalErrorMessage: (message) => {
    set(() => ({ globalErrorMessage: message }));
  },
  paymentError: undefined,
  setPaymentError: (error) => {
    set(() => ({ paymentError: error }));
  },
  paymentMethod: undefined,
  setPaymentMethod: (paymentMethod) => {
    set((state) => {
      state.paymentError && state.setPaymentError(undefined);

      track(ETrackingEvents.PAYMENT_METHOD_SELECTED, {
        cartId: state.cartId,
        paymentType: paymentMethod,
      });

      return { paymentMethod };
    });
  },
  burbankSportscardsSection: undefined,
  fanaticsAuthenticSection: undefined,
  fanaticsCollectSection: undefined,
  getShipping: (seller) => {
    const section = getSectionField(seller);
    return get()?.[section]?.shipping;
  },
  getAlternateAddress: (seller) => {
    const section = getSectionField(seller);
    return get()?.[section]?.alternateAddress;
  },
  updateSectionShipping: (shipping, seller) => {
    const section = getSectionField(seller);
    set((state) => ({
      [section]: {
        ...state[section],
        shipping: { ...state[section]?.shipping, ...shipping },
      },
    }));
  },
  setSectionAlternateAddress: (address, seller) => {
    const section = getSectionField(seller);
    set((state) => ({
      [section]: { ...state[section], alternateAddress: address },
    }));
  },
  setSectionDefaultAddressCost: (cost, seller) => {
    const section = getSectionField(seller);
    set((state) => ({
      [section]: { ...state[section], defaultAddressLowestCost: cost },
    }));
  },
  setSectionAlternateAddressCost: (cost, seller) => {
    const section = getSectionField(seller);
    set((state) => ({
      [section]: { ...state[section], alternateAddressLowestCost: cost },
    }));
  },
  getSectionDefaultAddressCost: (seller) => {
    const section = getSectionField(seller);
    return get()?.[section]?.defaultAddressLowestCost;
  },
  getSectionAlternateAddressCost: (seller) => {
    const section = getSectionField(seller);
    return get()?.[section]?.alternateAddressLowestCost;
  },

  paymentRequestMethods: undefined,
  setPaymentRequestMethods: (paymentRequestMethods) => {
    set(() => ({
      paymentRequestMethods,
    }));
  },

  savedPaymentMethodData: undefined,
  setSavedPaymentMethodData: (savedPaymentMethodData) => {
    set(() => ({
      savedPaymentMethodData,
    }));
  },

  availableItems: undefined,
  setAvailableItems: (availableItems) => {
    set(() => ({ availableItems }));
  },

  unavailableItems: undefined,
  setUnavailableItems: (unavailableItems) => {
    set(() => ({ unavailableItems }));
  },

  unavailableItemsError: false,
  setUnavailableItemsError: (value) => {
    set(() => ({ unavailableItemsError: value }));
  },

  checkoutDetails: undefined,
  setCheckoutDetails: (details) => {
    set(() => ({ checkoutDetails: details }));
  },

  shippingError: false,
  setShippingError: (value) => {
    set(() => ({ shippingError: value }));
  },
}));
