import { Box, Text } from "@chakra-ui/react";
import { ETextVariant, EColor } from "@fanatics-live/common-components";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type ICreditCardFeeBadgeProps = {
  creditCardFeePercentage: number;
};

const CreditCardFeeBadge: FC<ICreditCardFeeBadgeProps> = ({
  creditCardFeePercentage,
}) => {
  const { t } = useTranslation();

  return (
    <Box bg={EColor.Black} padding="1px 4px 0">
      <Text
        variant={ETextVariant.XSBold}
        color={EColor.White}
        whiteSpace="nowrap"
      >
        {t("order_summary.credit_card_fee_label", {
          percentage: creditCardFeePercentage,
        })}
      </Text>
    </Box>
  );
};

export { CreditCardFeeBadge };
