import { gql } from "./artifacts";

export const collectRemoveFromCart = gql(`
    mutation webRemoveFromCartCheckoutMutation(
      $cartId: UUID!
      $listingIds: [UUID!]!
    ) {
      collectRemoveFromCart(
        cartId: $cartId
        listingIds: $listingIds
      ) {
        successful
        messages {
          field
          message
          code
          template
          options {
            key
            value
          }
        }
      }
    }
  `);
