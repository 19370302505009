import { FC, HTMLAttributes, ReactNode } from "react";

import { EBadgeType } from "@/types/badgeTypes";
import { cn } from "@/utils";

import { Skeleton } from "./Skeleton";

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  type: EBadgeType;
  children: ReactNode;
  isLoading?: boolean;
}

const SuccessClassName = "bg-green-100 text-green-700 ";
const ErrorClassName = "bg-red-100 text-red-700 ";
const WarningClassName = "bg-orange-100 text-orange-700";
const NeutralClassName = "bg-neutral-50 text-neutral-950 ";
const TransparentClassName = "text-neutral-950 ";

const Badge: FC<BadgeProps> = ({
  type,
  children,
  className,
  isLoading,
  ...restProps
}) => {
  const getClassName = () => {
    switch (type) {
      case EBadgeType.Success:
        return SuccessClassName;
      case EBadgeType.Error:
        return ErrorClassName;
      case EBadgeType.Warning:
        return WarningClassName;
      case EBadgeType.Neutral:
        return NeutralClassName;
      case EBadgeType.Transparent:
        return TransparentClassName;
    }
  };
  return isLoading ? (
    <Skeleton className="h-6 w-16 rounded-md" />
  ) : (
    <div
      className={cn(
        "whitespace-nowrap rounded px-2 py-1 font-mono text-body-xs-bold uppercase",
        getClassName(),
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  );
};

export { Badge };
