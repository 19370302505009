import { useMutation } from "@apollo/client";
import { Info, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/shadcn/collapsible";
import { CollectCheckoutV2LineItem, Maybe } from "@/gql";
import { collectRemoveFromCart } from "@/gql/collectRemoveFromCart";
import { useMulticartStore } from "@/store/MulticartStore";
import { formatMonetaryValue, toDollars } from "@/utils";

export const UnavailableItemsBanner = () => {
  const { t } = useTranslation();
  const {
    cartId,
    unavailableItems,
    setUnavailableItems,
    setGlobalErrorMessage,
  } = useMulticartStore();
  const [removedIds, setRemovedIds] = useState<string[]>([]);

  const unavailableItemsRef = useRef<Maybe<CollectCheckoutV2LineItem>[]>(
    unavailableItems ?? [],
  );

  const [removeFromCart, { loading }] = useMutation(collectRemoveFromCart);

  const removeItems = async (listingIds: string[]) => {
    if (cartId) {
      await removeFromCart({
        variables: {
          cartId,
          listingIds,
        },
        onCompleted: () => {
          setUnavailableItems(undefined);
          setRemovedIds([...removedIds, ...listingIds]);
        },
        onError: (error) => {
          console.error("Error removing items from cart", error);
        },
      });
    }
  };

  useEffect(() => {
    const newItems = [
      ...new Map(
        [...unavailableItemsRef.current, ...(unavailableItems ?? [])].map(
          (item) => [item?.uuid, item],
        ),
      ).values(),
    ];

    unavailableItemsRef.current = newItems;
  }, [unavailableItems]);

  useEffect(() => {
    const listingIds = unavailableItems
      ?.map((i) => i?.uuid)
      .filter((id) => !removedIds.includes(id));

    const isUserFrozen = unavailableItems?.some(
      (i) => i?.error?.code === "frozen",
    );
    if (isUserFrozen) {
      setGlobalErrorMessage(t("errors.frozen_user"));
      return;
    }

    if (listingIds && listingIds.length > 0 && !loading) {
      removeItems(listingIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unavailableItemsRef.current]);

  return unavailableItemsRef.current.length > 0 ? (
    <Collapsible
      defaultOpen
      onOpenChange={(open) => {
        if (!open) {
          unavailableItemsRef.current = [];
        }
      }}
    >
      <CollapsibleContent className="collapsible-styles">
        <div className="flex w-full items-start justify-between gap-2 bg-red-100 px-8 py-3">
          <div className="flex justify-start gap-2">
            <div className="h-6 w-6">
              <Info className="stroke-red-700" width={24} height={24} />
            </div>
            <div className="flex flex-col items-start">
              <p className="text-body-md-bold text-red-700">
                {t("checkout_v2.multicart.banner.title")}
              </p>
              <p className="text-body-sm text-red-700">
                {t("checkout_v2.multicart.banner.description")}
              </p>
              <ul className="mt-2">
                {unavailableItemsRef.current.map((i) => (
                  <li
                    className="list-inside list-disc pl-4 text-body-sm text-red-700"
                    key={i?.id}
                  >
                    {i?.title},{" "}
                    <span className="font-bold">
                      {formatMonetaryValue(toDollars(i?.amount))}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <CollapsibleTrigger asChild>
            <div className="h-6 w-6">
              <X className="ml-auto cursor-pointer stroke-red-700" width={24} />
            </div>
          </CollapsibleTrigger>
        </div>
      </CollapsibleContent>
    </Collapsible>
  ) : null;
};
