import { t } from "i18next";

import { Label } from "@/components/shadcn/label";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/radio-group";
import { CollectShippingMethod } from "@/gql";
import { EBadgeType } from "@/types/badgeTypes";
import { cn, toDollars } from "@/utils";

import { Badge } from "../checkoutV2/common";

export const ShippingMethods = ({
  availableShippingMethods,
  onClick,
  selectedShippingMethodId,
}: {
  availableShippingMethods: CollectShippingMethod[];
  onClick: (id: CollectShippingMethod) => void;
  selectedShippingMethodId: CollectShippingMethod["id"];
}) => (
  <div className="mt-[12px] flex items-center">
    <RadioGroup className="w-full space-y-2 rounded-[8px] bg-neutral-50 p-[14px_12px]">
      {availableShippingMethods.map((method) => (
        <div key={method.id} className="flex items-start space-x-2">
          <Label
            className="flex cursor-pointer gap-2"
            onClick={() => onClick(method.id)}
          >
            <RadioGroupItem
              value={method.id}
              checked={method.id === selectedShippingMethodId}
              className="mt-[3px] border-neutral-300 bg-neutral-0"
            />
            <div className="flex items-center gap-1">
              <span className="text-body-sm-bold">{method.name}</span>
              <Badge
                type={
                  method.cost?.amountInCents === 0
                    ? EBadgeType.Success
                    : EBadgeType.Neutral
                }
                className={cn(
                  (method?.cost?.amountInCents ?? 0) > 0 &&
                    "bg-black bg-opacity-[0.04]",
                )}
              >
                {method.cost?.amountInCents === 0
                  ? t("cart.shipping.vault.free")
                  : t("cart.shipping.primary.cost", {
                      cost: toDollars(method.cost).toFixed(2),
                    })}
              </Badge>
            </div>
            {/* <div className="text-small">{method.description}</div> */}
          </Label>
        </div>
      ))}
    </RadioGroup>
  </div>
);
