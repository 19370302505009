import { Box, ModalHeader, Text } from "@chakra-ui/react";
import { ArrowIcon, EColor } from "@fanatics-live/common-components";
import { ReactNode } from "react";

import styles from "@/legacy/styles";

interface ICheckoutModalHeaderProps {
  children: ReactNode;
  onBackButtonClick: () => void;
}

export const CheckoutModalHeader = ({
  onBackButtonClick,
  children,
}: ICheckoutModalHeaderProps) => {
  return (
    <ModalHeader
      borderBottom={`1px solid ${EColor.Neutral15}`}
      minHeight="70px"
      display="flex"
      alignItems="center"
      gap="8px"
    >
      <Box
        as="button"
        h="24px"
        w="24px"
        mb="12px"
        ml="-6px"
        onClick={onBackButtonClick}
      >
        <ArrowIcon height="16px" width="10px" />
      </Box>
      <Box>
        <Text {...styles.h2} mb={0}>
          {children}
        </Text>
      </Box>
    </ModalHeader>
  );
};
