import { setContext } from "@apollo/client/link/context";
import { decodeJwt } from "jose";
import Cookies from "js-cookie";

import { ACCESS_TOKEN_COOKIE, MONOLITH_URL, REFRESH_TOKEN_COOKIE } from "@/env";
import { track } from "@/utils";
import { ETrackingEvents } from "@/utils/tracking";

import { refreshToken } from "./refreshToken";

const navigateToLogin = () => {
  const redirectUrl = encodeURIComponent(window.location.href);
  window.location.href = `${MONOLITH_URL}/?l&redirectUrl=${redirectUrl}`;
};

const isExpired = (token?: string) => {
  if (!token) {
    return true;
  }
  const { exp } = decodeJwt(token);
  if (!exp) {
    return false;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return exp < currentTime;
};

export const tokenLink = setContext(async (_, { headers }) => {
  try {
    const pwccAccessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
    const pwccRefreshToken = Cookies.get(REFRESH_TOKEN_COOKIE);

    const isTokenExpired = isExpired(pwccAccessToken);
    if (!isTokenExpired) {
      return { headers };
    }

    const isRefreshTokenExpired = isExpired(pwccRefreshToken);

    if (isRefreshTokenExpired) {
      let error: string;
      if (!pwccAccessToken && !pwccRefreshToken) {
        error = "User accessed checkout without logging in";
      }

      if (pwccAccessToken && !pwccRefreshToken) {
        error = "Refresh Token does not exist";
      } else {
        error = "Access Token is expired";
      }

      track(ETrackingEvents.TOKENS_EXPIRED, {
        error,
      });

      // If both tokens are expired redirect to login like we do in the main page
      navigateToLogin();
      return { headers };
    }

    const { data } = await refreshToken(pwccRefreshToken!);
    if (
      data?.collectRefreshToken?.accessToken &&
      data?.collectRefreshToken?.refreshToken
    ) {
      const decodedAccessToken = decodeJwt(
        data.collectRefreshToken.accessToken!,
      );

      const decodedRefreshToken = decodeJwt(
        data.collectRefreshToken.refreshToken,
      );

      // Functionality here taken from collect

      const accessTokenExpiration = new Date(
        (decodedAccessToken.exp || Date.now()) * 1000,
      );
      const accessTokenDomain =
        typeof decodedAccessToken.aud === "string"
          ? decodedAccessToken.aud
          : undefined;

      const refreshTokenExpiration = new Date(
        (decodedRefreshToken.exp || Date.now()) * 1000,
      );
      const refreshTokenDomain =
        typeof decodedRefreshToken.aud === "string"
          ? decodedRefreshToken.aud
          : undefined;

      Cookies.set(ACCESS_TOKEN_COOKIE, data.collectRefreshToken.accessToken, {
        expires: accessTokenExpiration,
        domain: accessTokenDomain,
      });
      Cookies.set(REFRESH_TOKEN_COOKIE, data.collectRefreshToken.refreshToken, {
        expires: refreshTokenExpiration,
        domain: refreshTokenDomain,
      });
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${data.collectRefreshToken.accessToken}`,
        },
      };
    } else {
      track(ETrackingEvents.WRONG_CREDENTIAL_DATA, {
        error: "Both tokens are expired",
        data,
      });
      navigateToLogin();
      return { headers };
    }
  } catch (err) {
    track(ETrackingEvents.UNEXPECTED_REFRESH_TOKEN_ERROR, {
      error: "Unexpected refresh token error",
      err,
    });
    navigateToLogin();
    return { headers };
  }
});
