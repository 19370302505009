import { useQuery } from "@apollo/client";
import { Flex, Text, Box, Button, Spinner, ModalBody } from "@chakra-ui/react";
import {
  EPaymentType,
  ETextVariant,
  IPaymentMethod,
  useIsMobile,
  usePaymentMethods,
} from "@fanatics-live/common-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CheckoutModal, CheckoutModalHeader } from "@/components";
import { CollectValidPaymentMethod, collectCurrentUser } from "@/gql";
import { useCheckoutDetails, useIdParam, useCartContext } from "@/hooks";
import styles from "@/legacy/styles";
import {
  isBalanceCoveredByFunds,
  isSavedPaymentMethod,
  toDollars,
} from "@/utils";

import { AccountFunds } from "./AccountFunds";
import {
  AdditionalPaymentMethod,
  isAdditionalPaymentMethod,
} from "./AdditionalPaymentMethod";
import { ChoosePaymentMethod } from "./ChoosePaymentMethod";
import { SavedPaymentMethod } from "./SavedPaymentMethod";
import { WireContent } from "./WireContent";

// TODO: Update common components to use Collect GraphQL types.
export const paymentMethodMap = {
  [EPaymentType.ACCOUNT_FUNDS]: CollectValidPaymentMethod.Funds,
  [EPaymentType.APPLE_PAY]: CollectValidPaymentMethod.ApplePay,
  [EPaymentType.BANK_ACCOUNT]: CollectValidPaymentMethod.Ach,
  [EPaymentType.CARD]: CollectValidPaymentMethod.Card,
  [EPaymentType.GOOGLE_PAY]: CollectValidPaymentMethod.GooglePay,
  [EPaymentType.WIRE]: CollectValidPaymentMethod.Wire,
};

export const PaymentSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { integerId } = useIdParam();
  const {
    detailsOptions,
    paymentMethod,
    paymentRequestMethods,
    savedPaymentMethodData,
    setSavedPaymentMethodData,
    setPaymentMethod,
  } = useCartContext();
  const savedPaymentMethods = usePaymentMethods();
  const { details, loading: detailsLoading } =
    useCheckoutDetails(detailsOptions);
  const { data: userData } = useQuery(collectCurrentUser);
  const [paymentOptionsVisible, setPaymentOptionsVisible] = useState(false);

  const loading = detailsLoading || savedPaymentMethods.isLoading;
  const accountFundsToUse = toDollars(details?.accountFundsToUse);
  const fundsCoverBalance = isBalanceCoveredByFunds(
    paymentMethod,
    details?.accountFundsToUse,
    details?.potentialTotalWithCreditCardFee,
    details?.amountPaidOutstanding || details?.total,
  );
  const showPaymentOptions = !(fundsCoverBalance || paymentOptionsVisible);

  const creditCardFeePercentage = parseFloat(
    ((details?.creditCardFeePct ?? 0) * 100).toFixed(2),
  );

  // Set the initial payment method and data.
  useEffect(() => {
    if (!loading && details) {
      const requiresSecondPaymentMethod =
        paymentMethod === CollectValidPaymentMethod.Funds && !fundsCoverBalance;
      const requiresPaymentMethodData =
        !savedPaymentMethodData &&
        (isSavedPaymentMethod(paymentMethod) || requiresSecondPaymentMethod);

      if (!paymentMethod || requiresPaymentMethodData) {
        if (fundsCoverBalance) {
          setPaymentMethod(CollectValidPaymentMethod.Funds, details);
        } else {
          const defaultPaymentMethod =
            savedPaymentMethods?.data?.data.find((item) => item.default) ||
            savedPaymentMethods?.data?.data[0];

          if (defaultPaymentMethod) {
            setSavedPaymentMethodData(defaultPaymentMethod);
            setPaymentMethod(
              paymentMethodMap[defaultPaymentMethod.type],
              details,
            );
          } else {
            setPaymentMethod(
              paymentRequestMethods?.ApplePay
                ? CollectValidPaymentMethod.ApplePay
                : paymentRequestMethods?.GooglePay
                  ? CollectValidPaymentMethod.GooglePay
                  : details.allowedPaymentMethods?.includes(
                        CollectValidPaymentMethod.Wire,
                      )
                    ? CollectValidPaymentMethod.Wire
                    : undefined,
              details,
            );
          }
        }
      }
    }
  }, [
    fundsCoverBalance,
    details,
    loading,
    paymentMethod,
    paymentRequestMethods,
    savedPaymentMethodData,
    savedPaymentMethods,
    setPaymentMethod,
    setSavedPaymentMethodData,
  ]);

  const choosePaymentMethod = details && (
    <ChoosePaymentMethod
      allowedPaymentMethods={details.allowedPaymentMethods}
      listingId={integerId}
      onSelect={(method: CollectValidPaymentMethod, data?: IPaymentMethod) => {
        setPaymentMethod(method, details);
        setSavedPaymentMethodData(data);
        setPaymentOptionsVisible(false);
      }}
      savedPaymentMethods={savedPaymentMethods}
      selectedPaymentMethod={paymentMethod}
      selectedPaymentMethodData={savedPaymentMethodData}
      creditCardFeePercentage={creditCardFeePercentage}
    />
  );

  return (
    <Flex
      flexDir="column"
      {...styles.sectionBox}
      pr={{ base: "16px", md: 0 }}
      transition="max-height 900ms linear"
    >
      <Box>
        <Text {...styles.h2}>{t("cart.payment.title")}</Text>
      </Box>
      {details ? (
        <Box>
          <AccountFunds
            accountFunds={toDollars(
              userData?.collectCurrentUserV2?.accountFunds?.usableFunds,
            )}
            accountFundsToUse={accountFundsToUse}
            mb={{ base: 0, md: "16px" }}
            {...(fundsCoverBalance && { borderBottom: "none" })}
          />

          {showPaymentOptions && (
            <>
              <Flex justifyContent="space-between">
                <Flex alignItems="center" gap="16px">
                  {paymentMethod ? (
                    isAdditionalPaymentMethod(paymentMethod) ? (
                      <AdditionalPaymentMethod
                        paymentMethod={paymentMethod}
                        creditCardFeePercentage={creditCardFeePercentage}
                      />
                    ) : (
                      <SavedPaymentMethod
                        paymentMethodData={savedPaymentMethodData}
                        creditCardFeePercentage={creditCardFeePercentage}
                      />
                    )
                  ) : (
                    <Text variant={ETextVariant.BaseBold}>
                      {t("cart.payment.choose")}
                    </Text>
                  )}
                </Flex>

                <Button
                  {...styles.editButton}
                  mr={{ base: 0, md: "23px" }}
                  onClick={() => setPaymentOptionsVisible(true)}
                >
                  {paymentMethod ? t("btn.change") : t("btn.add")}
                </Button>
              </Flex>

              {paymentMethod === CollectValidPaymentMethod.Wire && (
                <WireContent />
              )}
            </>
          )}

          {isMobile ? (
            <CheckoutModal isOpen={paymentOptionsVisible}>
              <Box mx="-8px">
                <CheckoutModalHeader
                  onBackButtonClick={() => setPaymentOptionsVisible(false)}
                >
                  {t("cart.payment.title")}
                </CheckoutModalHeader>
              </Box>
              <ModalBody pb="24px" px="16px">
                {choosePaymentMethod}
              </ModalBody>
            </CheckoutModal>
          ) : (
            paymentOptionsVisible && choosePaymentMethod
          )}
        </Box>
      ) : (
        <Box textAlign="center">
          <Spinner />
        </Box>
      )}
    </Flex>
  );
};
