import "@/locales/i18n";
import {
  useFaviconLink,
  ApiProvider,
  AuthProvider,
  useFetchProfile,
} from "@fanatics-live/common-components";
import { SplitFactoryProvider } from "@splitsoftware/splitio-react";
import { StrictMode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";
import Zendesk from "react-zendesk";

import { CheckoutError } from "@/components";
import {
  GTM_ID,
  ZENDESK_KEY,
  API_BASE_URL,
  GRAPHQL_API_URL,
  MEMBERS_API_URL,
  MONOLITH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SPLIT_CLIENT_KEY,
  BUILD_VERSION,
} from "@/env";
import { DatadogProvider, QueryProvider, ThemeProvider } from "@/providers";
import {
  getFaviconPath,
  apiClient,
  auth,
  ETrackingEvents,
  track,
} from "@/utils";

import AppRouter from "./AppRouter";
import { ApolloProvider } from "./providers/ApolloProvider";
import { ModalProvider } from "./providers/ModalProvider";

// eslint-disable-next-line no-console
console.log(`Build: ${BUILD_VERSION}`);

// Deprecated: Switching to Segment, remove with rewrite
if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

export const App = () => {
  const faviconPath = getFaviconPath();
  useFaviconLink(faviconPath);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigateToLogin = (data?: any) => {
    track(ETrackingEvents.FETCH_PROFILE_ERROR, data);
    window.location.href = `${MONOLITH_URL}/?l`;
  };

  const { authState, isInitializing, refetchProfile } = useFetchProfile(
    auth,
    apiClient,
    navigateToLogin,
  );

  const splitConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_CLIENT_KEY as string,
      key: authState.profile?.userUuid || "anonymous",
    },
  };

  return (
    <ApolloProvider>
      <StrictMode>
        <ThemeProvider>
          <SplitFactoryProvider config={splitConfig}>
            <DatadogProvider>
              <ErrorBoundary
                FallbackComponent={() => <CheckoutError height="100vh" />}
              >
                <AuthProvider value={authState}>
                  <ApiProvider value={apiClient}>
                    <QueryProvider>
                      <ModalProvider
                        membersApiUrl={MEMBERS_API_URL}
                        fanaticsCollectBaseUrl={MONOLITH_URL}
                        checkoutApiUrl={API_BASE_URL}
                        addPaymentMethodLoginFlow={false} // TODO: use feature flag from split.io
                        graphqlApiUrl={GRAPHQL_API_URL ?? ""}
                        fanIdBaseUrl={FAN_ID_BASE_URL ?? ""}
                        fanIdClientId={FAN_ID_CLIENT_ID ?? ""}
                        fanIdPreview={FAN_ID_PREVIEW}
                        skipApolloProvider
                        profileId={authState.profile?.userUuid ?? ""}
                      >
                        <AppRouter
                          authState={authState}
                          refetchProfile={refetchProfile}
                          isInitializing={isInitializing}
                        />
                      </ModalProvider>
                    </QueryProvider>
                  </ApiProvider>
                </AuthProvider>
              </ErrorBoundary>
            </DatadogProvider>
          </SplitFactoryProvider>
        </ThemeProvider>
        <Zendesk defer zendeskKey={ZENDESK_KEY ?? ""} />
      </StrictMode>
    </ApolloProvider>
  );
};
