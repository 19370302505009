import { FC, useState } from "react";

import { FanaticsIcon } from "@/components/icons";
import { CollectCheckoutV2LineItem } from "@/gql";
import { cn, formatMonetaryValue, toDollars } from "@/utils";

interface LineItemProps {
  item: CollectCheckoutV2LineItem;
  showBorder: boolean;
}

const LineItem: FC<LineItemProps> = ({ item, showBorder }) => {
  const [imageError, setImageError] = useState(false);
  return (
    <div
      key={item.title}
      className={cn(
        "flex w-full items-start gap-6 py-3",
        showBorder && "border-b border-neutral-100",
      )}
    >
      <div className="flex h-[128px] flex-[0_0_128px] items-center justify-center bg-neutral-50 p-3 lg:h-[152px] lg:flex-[0_0_152px]">
        {item.images?.[0] && !imageError ? (
          <img
            alt={item.title || ""}
            src={item.images?.[0] || ""}
            className="h-full w-full object-contain"
            onError={() => {
              setImageError(true);
            }}
          />
        ) : (
          <FanaticsIcon width="100%" />
        )}
      </div>
      <div>
        <h3 className="text-body-md">{item.title}</h3>
        {item.description && (
          <p className="font-mono text-body-xs uppercase text-neutral-500">
            {item.description}
          </p>
        )}
        <div className="flex gap-2">
          <p className="pt-2 text-body-md-bold">
            {formatMonetaryValue(toDollars(item.amount))}
          </p>
        </div>
      </div>
    </div>
  );
};

export { LineItem };
