import { Box, Button, Flex, Link, ModalBody, Text } from "@chakra-ui/react";
import {
  ArrowIcon,
  EColor,
  ETextVariant,
  useIsMobile,
} from "@fanatics-live/common-components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Alert,
  CheckoutModal,
  CheckoutModalHeader,
  InfoIcon,
  OctagonAlert,
} from "@/components";
import { CollectShippingMethod, CollectShippingType } from "@/gql";
import { useCheckoutDetails, useCartContext } from "@/hooks";
import styles from "@/legacy/styles";

import { AlternateAddressForm } from "./AlternateAddressForm";
import { ShippingMethods } from "./ShippingMethods";
import { ShippingOption } from "./ShippingOption";
import {
  hasCombinedShippingBanner,
  getValidShippingMethods,
  shippingTypeIsSet,
  convertAlternatingToCheckoutAddress,
} from "./utils";

export const ShippingSection = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { detailsOptions, setDetailsOptions, shippingError, setShippingError } =
    useCartContext();

  const {
    details,
    loading: detailsLoading,
    error: detailsError,
  } = useCheckoutDetails(detailsOptions);

  const [optionsVisible, setOptionsVisible] = useState(isMobile ? false : true);
  const [alternateAddressFormVisible, setAlternateAddressFormVisible] =
    useState(false);
  const [hasAlternateAddressError, setHasAlternateAddressError] =
    useState<boolean>();
  const [hasDefaultAddressError, setHasDefaultAddressError] =
    useState<boolean>();

  // UPS, FEDEX, etc. Currently only Burbank.
  const validShippingMethods = getValidShippingMethods(
    detailsOptions?.shippingType,
    details?.availableShippingMethods,
    detailsOptions.defaultShippingAddress,
    detailsOptions.alternateShippingAddress,
  );
  const hasShippingMethods = !!validShippingMethods.length;
  const availableShippingTypes = details?.availableShippingTypes ?? [];
  const canShipToVault = availableShippingTypes.includes(
    CollectShippingType.Vault,
  );

  // Invalid address error
  const invalidAddressError = details?.errors?.some(
    (error) => error?.code === "get_tax_error",
  );

  // If the alternate address or default address was invalid, 'CollectCheckoutDetails' will return an
  // 'get_tax_error' in the 'errors' field. Intercept, show the error, and keep the
  // alternate address form or default address open with an error message.
  useEffect(() => {
    if (
      !detailsLoading &&
      detailsOptions.shippingType === CollectShippingType.New
    ) {
      if (invalidAddressError) {
        setHasAlternateAddressError(true);
      } else {
        setAlternateAddressFormVisible(false);
        setHasAlternateAddressError(false);
        setOptionsVisible(false);
        window.scrollTo({ top: window.scrollY - 478 }); // scroll position - form height
      }
    } else if (!detailsLoading && detailsOptions.shippingType !== undefined) {
      if (invalidAddressError) {
        setHasDefaultAddressError(true);
      } else {
        setHasDefaultAddressError(false);
        setOptionsVisible(false);
      }
    }
  }, [
    detailsError,
    detailsLoading,
    hasAlternateAddressError,
    detailsOptions,
    setDetailsOptions,
    invalidAddressError,
  ]);

  const handleSelect = (shippingType: CollectShippingType) => {
    setDetailsOptions({
      ...detailsOptions,
      shippingType,
      shippingMethodId: undefined,
    });

    if (optionsVisible) {
      setOptionsVisible(false);
    }
    if (shippingError) {
      setShippingError(false);
    }
  };

  const handleSelectShippingMethod = (
    shippingMethodId: CollectShippingMethod,
  ) => {
    setDetailsOptions({
      ...detailsOptions,
      shippingMethodId,
    });

    if (optionsVisible) {
      setOptionsVisible(false);
    }
    if (shippingError) {
      setShippingError(false);
    }
  };

  const bodyContent = (
    <>
      {alternateAddressFormVisible && (
        <Box
          {...(!isMobile && {
            borderY: "1px",
            borderColor: EColor.Neutral15,
            py: "20px",
          })}
          mb="24px"
          mt="16px"
        >
          {!isMobile && (
            <Text {...styles.h3}>
              {t("cart.shipping.alternate.form_title")}
            </Text>
          )}

          <AlternateAddressForm
            fulfillmentChargesLoading={detailsLoading}
            fulfillmentChargesErrorMessage={
              hasAlternateAddressError &&
              detailsOptions.shippingType === CollectShippingType.New
                ? t("cart.shipping.alternate.invalid_message")
                : undefined
            }
            defaultAddress={convertAlternatingToCheckoutAddress(
              detailsOptions.alternateShippingAddress,
            )}
            onSubmit={(alternateShippingAddress) => {
              setHasAlternateAddressError(false);
              setDetailsOptions({
                ...detailsOptions,
                shippingMethodId: undefined,
                shippingType: CollectShippingType.New,
                alternateShippingAddress,
              });
              setOptionsVisible(false);
            }}
            onCancel={() => {
              setHasAlternateAddressError(undefined);
              setAlternateAddressFormVisible(false);
              setOptionsVisible(true);
            }}
          />
        </Box>
      )}

      {!(isMobile && alternateAddressFormVisible) && (
        <>
          {alternateAddressFormVisible
            ? null
            : details?.shippingType &&
              detailsOptions.shippingType && (
                <Flex flexWrap="wrap">
                  <Flex w="100%">
                    <ShippingOption
                      alternateShippingAddress={
                        detailsOptions.alternateShippingAddress
                      }
                      collectDefaultAddress={
                        detailsOptions.defaultShippingAddress
                      }
                      shipping={details.shipping}
                      shippingMethods={validShippingMethods}
                      shippingType={
                        detailsOptions.shippingType ||
                        CollectShippingType.Default
                      }
                      tax={details.tax}
                      collectDefaultAddressError={hasDefaultAddressError}
                      alternateAddressError={hasAlternateAddressError}
                    />
                    <Box flex="1" textAlign="right">
                      {optionsVisible
                        ? availableShippingTypes.length > 1 && (
                            <Text
                              variant={ETextVariant.XS2Mono}
                              color={EColor.Neutral65}
                              fontWeight="700"
                              textTransform="uppercase"
                              mb="10px"
                            >
                              {t("cart.shipping.selected")}
                            </Text>
                          )
                        : !isMobile && (
                            <Button
                              {...styles.editButton}
                              onClick={() => setOptionsVisible(true)}
                            >
                              {t("btn.change")}
                            </Button>
                          )}
                    </Box>
                  </Flex>

                  {hasShippingMethods && (
                    <Box w="100%">
                      {shippingError && !detailsOptions.shippingMethodId && (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          bg="red.100"
                          p="12px"
                          borderRadius="8px"
                        >
                          <OctagonAlert className="h-4 w-4" />
                          <Text variant={ETextVariant.SmallBold}>
                            {t("common.select_shipping_type_error")}
                          </Text>
                        </Box>
                      )}
                      <ShippingMethods
                        availableShippingMethods={validShippingMethods}
                        onClick={handleSelectShippingMethod}
                        selectedShippingMethodId={
                          detailsOptions.shippingMethodId
                        }
                      />
                      {!canShipToVault && (
                        <Alert
                          className="mb-0 mt-2 bg-blue-100"
                          description={t(
                            "cart.shipping.burbank_banner.message",
                          )}
                          title={t("cart.shipping.burbank_banner.title")}
                        />
                      )}
                    </Box>
                  )}
                </Flex>
              )}

          {optionsVisible && (
            <>
              <Box pt="8px" borderColor={EColor.Neutral15}>
                {availableShippingTypes.map((shippingType, index) => {
                  if (
                    shippingType &&
                    shippingTypeIsSet(shippingType, detailsOptions)
                  ) {
                    const shippingMethods = getValidShippingMethods(
                      shippingType,
                      details?.availableShippingMethods,
                      detailsOptions.defaultShippingAddress,
                      detailsOptions.alternateShippingAddress,
                    );

                    return (
                      <Box
                        key={shippingType}
                        borderTop={isMobile && index === 0 ? 0 : "1px"}
                        borderColor={EColor.Neutral15}
                        py="16px"
                      >
                        <ShippingOption
                          alternateShippingAddress={
                            detailsOptions.alternateShippingAddress
                          }
                          collectDefaultAddress={
                            detailsOptions.defaultShippingAddress
                          }
                          shipping={details?.shipping}
                          shippingMethods={shippingMethods}
                          shippingType={shippingType}
                          tax={details?.tax}
                          collectDefaultAddressError={hasDefaultAddressError}
                          alternateAddressError={hasAlternateAddressError}
                        />
                        <Link
                          {...styles.selectLink}
                          as="button"
                          onClick={() => {
                            handleSelect(shippingType);
                          }}
                        >
                          {t("btn.select")}
                        </Link>
                      </Box>
                    );
                  }
                })}
                {!canShipToVault && (
                  <Alert
                    className="mb-5 bg-blue-100"
                    description={t("cart.shipping.burbank_banner.message")}
                    title={t("cart.shipping.burbank_banner.title")}
                  />
                )}
              </Box>

              {!alternateAddressFormVisible &&
                (isMobile ? (
                  <Flex
                    as="button"
                    borderTop="1px"
                    borderBottom="1px"
                    borderColor={EColor.Neutral15}
                    py="20px"
                    w="100%"
                    onClick={() => {
                      setAlternateAddressFormVisible(true);
                    }}
                  >
                    <Text {...styles.h3} mt={{ base: "4px", md: 0 }}>
                      {t("cart.shipping.alternate.form_title")}
                    </Text>
                    <Box flex={1} textAlign="right">
                      <ArrowIcon
                        transform="scaleX(-1)"
                        height="16px"
                        width="10px"
                        mr="18px"
                      />
                    </Box>
                  </Flex>
                ) : (
                  <Flex
                    alignItems="center"
                    borderTop="1px"
                    borderColor={EColor.Neutral15}
                    pt="20px"
                  >
                    <Text {...styles.h3} mb="0">
                      {t("cart.shipping.alternate.form_title")}
                    </Text>
                    <Box flex={1} textAlign="right">
                      <Button
                        {...styles.editButton}
                        px="23px"
                        onClick={() => {
                          setOptionsVisible(false);
                          setAlternateAddressFormVisible(true);
                        }}
                      >
                        {detailsOptions.alternateShippingAddress
                          ? t("btn.change")
                          : t("btn.add")}
                      </Button>
                    </Box>
                  </Flex>
                ))}
            </>
          )}
        </>
      )}
    </>
  );

  return isMobile && optionsVisible ? (
    <CheckoutModal isOpen={true}>
      {alternateAddressFormVisible ? (
        <CheckoutModalHeader
          onBackButtonClick={() => {
            setAlternateAddressFormVisible(false);
          }}
        >
          {t("cart.shipping.alternate.form_title")}
        </CheckoutModalHeader>
      ) : (
        <CheckoutModalHeader
          {...styles.h2}
          onBackButtonClick={() => {
            setOptionsVisible(false);
          }}
        >
          {t("cart.shipping.title")}
        </CheckoutModalHeader>
      )}
      <ModalBody p="24px">{bodyContent}</ModalBody>
    </CheckoutModal>
  ) : (
    <Box
      {...styles.sectionBox}
      pb={{ base: "30px", md: alternateAddressFormVisible ? "4px" : "20px" }}
      overflow="hidden"
    >
      {isMobile ? (
        <Flex alignItems="center" justifyContent="space-between" mb="14px">
          <Text {...styles.h2} mb={0}>
            {t("cart.shipping.title")}
          </Text>
          <Button
            {...styles.editButton}
            onClick={() => setOptionsVisible(true)}
          >
            {!detailsOptions.shippingType ? t("btn.select") : t("btn.change")}
          </Button>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="space-between">
          <Text {...styles.h2}>{t("cart.shipping.title")}</Text>
        </Flex>
      )}

      {shippingError && !detailsOptions.shippingType && (
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          bg="red.100"
          p="12px"
          borderRadius="8px"
        >
          <OctagonAlert className="h-4 w-4" />
          <Text variant={ETextVariant.SmallBold}>
            {t("common.select_shipping_type_error")}
          </Text>
        </Box>
      )}
      {bodyContent}

      {hasCombinedShippingBanner(optionsVisible, detailsOptions, details) &&
        !alternateAddressFormVisible && (
          <Flex
            alignItems="center"
            gap="16px"
            {...styles.banner}
            mb={{ base: "-30px", md: "-20px" }}
            mr={{ base: "-16px", md: "-24px" }}
            ml="-24px"
            mt="24px"
          >
            <InfoIcon />
            <Box>
              <Text variant={ETextVariant.Heading3} lineHeight="24px">
                {t("cart.shipping.banner.title")}
              </Text>
              <Text variant={ETextVariant.XS}>
                {t("cart.shipping.banner.message")}
              </Text>
            </Box>
          </Flex>
        )}
    </Box>
  );
};
