import {
  Box,
  Button,
  Circle,
  Collapse,
  Flex,
  FlexProps,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  AddPaymentMethod,
  CardAmexIcon,
  CardDiscoverIcon,
  CardMastercardIcon,
  CardVisaIcon,
  EColor,
  EPaymentType,
  ETextVariant,
  IPaymentMethod,
  useIsMobile,
  usePaymentMethods,
} from "@fanatics-live/common-components";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

import { WireTransferIcon } from "@/components";
import { CollectListing, CollectValidPaymentMethod } from "@/gql";
import { useCartContext } from "@/hooks";
import styles from "@/legacy/styles";
import { isExpiredCard } from "@/utils";

import { AdditionalPaymentMethod } from "./AdditionalPaymentMethod";
import { CreditCardFeeBadge } from "./CreditCardFeeBadge";
import { paymentMethodMap } from "./PaymentSection";
import { SavedPaymentMethod } from "./SavedPaymentMethod";
import { WireContent } from "./WireContent";

interface IChoosePaymentMethodProps {
  allowedPaymentMethods?: (CollectValidPaymentMethod | null)[] | null;
  listingId: CollectListing["id"];
  onSelect: (
    selectedPaymentMethod: CollectValidPaymentMethod,
    data?: IPaymentMethod,
  ) => void;
  savedPaymentMethods: ReturnType<typeof usePaymentMethods>;
  selectedPaymentMethod?: CollectValidPaymentMethod;
  selectedPaymentMethodData?: IPaymentMethod;
  creditCardFeePercentage: number | undefined;
}

interface IItemWrapper extends FlexProps {
  isSelected: boolean;
  isDisabled: boolean;
  children: ReactNode;
  showBorder?: boolean;
}

// TODO: Set in common components or backend response.
const maxPaymentMethods = 10;

export const ItemWrapper = ({
  isSelected,
  isDisabled,
  children,
  showBorder = true,
  ...restProps
}: IItemWrapper) => (
  <Flex
    as={isDisabled ? "div" : "button"}
    py="22px"
    borderTop={showBorder ? `1px solid ${EColor.Neutral15}` : "none"}
    alignItems="center"
    gap="16px"
    {...restProps}
  >
    <Circle
      visibility={isDisabled ? "hidden" : "visible"}
      size="20px"
      borderWidth="1px"
      borderColor={EColor.BrandCorduroy}
    >
      {isSelected && <Circle size="14px" bgColor={EColor.BrandDark} />}
    </Circle>
    {children}
  </Flex>
);

export const ChoosePaymentMethod = ({
  allowedPaymentMethods,
  onSelect,
  savedPaymentMethods,
  selectedPaymentMethod,
  selectedPaymentMethodData,
  creditCardFeePercentage,
}: IChoosePaymentMethodProps) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();
  const { paymentRequestMethods } = useCartContext();

  const [paymentMethodToAdd, setPaymentMethodToAdd] = useState<
    EPaymentType.CARD | EPaymentType.BANK_ACCOUNT
  >();

  const {
    data: paymentMethodsData,
    error: paymentMethodsError,
    isLoading: paymentMethodsLoading,
  } = savedPaymentMethods;

  const hasPaymentMethod =
    !!(allowedPaymentMethods?.length || paymentMethodToAdd) &&
    !!selectedPaymentMethod;
  const hasMaxPaymentMethods =
    (paymentMethodsData?.data.length ?? 0) >= maxPaymentMethods;
  const showCard = allowedPaymentMethods?.includes(
    CollectValidPaymentMethod.Card,
  );
  const showWire = allowedPaymentMethods?.includes(
    CollectValidPaymentMethod.Wire,
  );
  const showApplePay =
    paymentRequestMethods?.applePay &&
    allowedPaymentMethods?.includes(CollectValidPaymentMethod.ApplePay);
  const showGooglePay =
    paymentRequestMethods?.googlePay &&
    allowedPaymentMethods?.includes(CollectValidPaymentMethod.GooglePay);

  const borders = {
    wire: hasPaymentMethod,
    applePay: hasPaymentMethod || showWire,
    googlePay: hasPaymentMethod || showWire || showApplePay,
    addCard: hasPaymentMethod || showWire || showApplePay || showGooglePay,
    addBankAccount:
      hasPaymentMethod ||
      showWire ||
      showApplePay ||
      showGooglePay ||
      (showCard && !hasMaxPaymentMethods),
  };

  const addPaymentMethod = (
    method?: EPaymentType.CARD | EPaymentType.BANK_ACCOUNT,
  ) => {
    setPaymentMethodToAdd(method);
    window.scrollTo(0, 0);
  };

  const handleSuccessAddPayment = () => {
    setPaymentMethodToAdd(undefined);
    queryClient.invalidateQueries({ queryKey: ["get-payment-methods"] });
  };

  const handleCancelAddPayment = () => setPaymentMethodToAdd(undefined);

  return (
    <Box mt={{ base: "-8px", md: hasPaymentMethod ? "-16px" : 0 }}>
      <Collapse in={!!paymentMethodToAdd} animateOpacity unmountOnExit>
        <Box
          borderTop={{ base: "none", md: `1px solid ${EColor.Neutral15}` }}
          mt="24px"
          pt="14px"
          pb="34px"
        >
          <Box maxW={{ base: "100%", md: "327px" }}>
            <Text variant={ETextVariant.BaseBold} fontWeight={600} mb="12px">
              {paymentMethodToAdd === EPaymentType.CARD &&
                t("cart.payment.add_payment_method.add_new_card")}
              {paymentMethodToAdd === EPaymentType.BANK_ACCOUNT &&
                t("cart.payment.add_payment_method.add_new_banking_account")}
            </Text>
            <AddPaymentMethod
              paymentTypes={[paymentMethodToAdd!]}
              onSuccess={handleSuccessAddPayment}
              onCancel={handleCancelAddPayment}
              submitButtonText={t("btn.save")}
            />
          </Box>
        </Box>
      </Collapse>

      {!(isMobile && paymentMethodToAdd) && (
        <>
          <Flex flexDirection="column">
            {paymentMethodsLoading ? (
              <Box textAlign="center">
                <Spinner />
              </Box>
            ) : (
              !paymentMethodsError &&
              paymentMethodsData?.data.map((method, index) => {
                const isExpired =
                  !!method.card &&
                  isExpiredCard(method.card?.exp_month, method.card?.exp_year);
                return (
                  <ItemWrapper
                    key={method.id}
                    isDisabled={isExpired}
                    isSelected={selectedPaymentMethodData?.id === method.id}
                    showBorder={
                      index === 0 && !paymentMethodToAdd ? false : true
                    }
                    onClick={() =>
                      !isExpired &&
                      onSelect(paymentMethodMap[method.type], method)
                    }
                  >
                    <SavedPaymentMethod
                      paymentMethodData={method}
                      creditCardFeePercentage={creditCardFeePercentage}
                    />
                  </ItemWrapper>
                );
              })
            )}

            {showWire && (
              <>
                <ItemWrapper
                  borderTop={
                    borders.wire
                      ? `1px solid ${EColor.BrandDarkAlabaster}`
                      : "none"
                  }
                  isSelected={
                    selectedPaymentMethod === CollectValidPaymentMethod.Wire &&
                    !isMobile
                  }
                  isDisabled={false}
                  onClick={() => onSelect(CollectValidPaymentMethod.Wire)}
                >
                  <>
                    <WireTransferIcon w="36px" h="24px" />
                    <Text variant={ETextVariant.BaseBold}>
                      {t("cart.payment.wire.title")}
                    </Text>
                  </>
                </ItemWrapper>
                <Collapse
                  in={
                    selectedPaymentMethod === CollectValidPaymentMethod.Wire &&
                    !isMobile
                  }
                >
                  <Box pb="24px" pl="36px" mt="-21px">
                    <WireContent />
                  </Box>
                </Collapse>
              </>
            )}

            {showApplePay && (
              <ItemWrapper
                isDisabled={false}
                isSelected={
                  selectedPaymentMethod === CollectValidPaymentMethod.ApplePay
                }
                showBorder={borders.applePay}
                py="19px"
                onClick={() => onSelect(CollectValidPaymentMethod.ApplePay)}
              >
                <AdditionalPaymentMethod
                  paymentMethod={CollectValidPaymentMethod.ApplePay}
                  creditCardFeePercentage={creditCardFeePercentage}
                />
              </ItemWrapper>
            )}

            {showGooglePay && (
              <ItemWrapper
                isDisabled={false}
                isSelected={
                  selectedPaymentMethod === CollectValidPaymentMethod.GooglePay
                }
                showBorder={borders.googlePay}
                py="19px"
                onClick={() => onSelect(CollectValidPaymentMethod.GooglePay)}
              >
                <AdditionalPaymentMethod
                  paymentMethod={CollectValidPaymentMethod.GooglePay}
                  creditCardFeePercentage={creditCardFeePercentage}
                />
              </ItemWrapper>
            )}

            {paymentMethodToAdd !== EPaymentType.CARD &&
              !hasMaxPaymentMethods && (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  pt="13px"
                  pb={paymentMethodToAdd ? 0 : "13px"}
                  borderTop={
                    borders.addCard ? `1px solid ${EColor.Neutral15}` : "none"
                  }
                  gap="4px"
                >
                  <Flex flexDir="column">
                    <Box
                      display="flex"
                      alignItems="start"
                      gap={{ base: "4px", md: "16px" }}
                    >
                      <Text
                        variant={ETextVariant.Paragraph6}
                        fontWeight={600}
                        color={EColor.BrandDark}
                      >
                        {t("cart.payment.desktop.payment_method.add_new_card")}
                      </Text>
                      {creditCardFeePercentage && (
                        <Box height="24px" display="flex" alignItems="center">
                          <CreditCardFeeBadge
                            creditCardFeePercentage={creditCardFeePercentage}
                          />
                        </Box>
                      )}
                    </Box>
                    <Flex gap="4px">
                      <CardVisaIcon h="24px" w="25px" />
                      <CardMastercardIcon h="24px" w="25px" />
                      <CardAmexIcon h="24px" w="25px" />
                      <CardDiscoverIcon h="24px" w="25px" />
                    </Flex>
                  </Flex>
                  <Button
                    {...styles.editButton}
                    px="23px"
                    mr="24px"
                    onClick={() => addPaymentMethod(EPaymentType.CARD)}
                  >
                    {t("btn.add")}
                  </Button>
                </Flex>
              )}

            {paymentMethodToAdd !== EPaymentType.BANK_ACCOUNT &&
              !hasMaxPaymentMethods && (
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  pt="20px"
                  borderTop={
                    borders.addBankAccount
                      ? `1px solid ${EColor.Neutral15}`
                      : "none"
                  }
                >
                  <Text
                    variant={ETextVariant.Paragraph6}
                    fontWeight={600}
                    color={EColor.BrandDark}
                  >
                    {t(
                      "cart.payment.desktop.payment_method.add_new_us_bank_account",
                    )}
                  </Text>
                  <Button
                    {...styles.editButton}
                    px="23px"
                    mr="24px"
                    onClick={() => addPaymentMethod(EPaymentType.BANK_ACCOUNT)}
                  >
                    {t("btn.add")}
                  </Button>
                </Flex>
              )}
          </Flex>
        </>
      )}
    </Box>
  );
};
